<template>
  <b-card>
    <validation-observer ref="simpleRules" #default="{ invalid }">
      <b-form>
        <b-tabs align="center">
          <b-tab title="Info">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Nombre Completo *" label-for="fullname">
                  <validation-provider #default="{ errors }" name="fullname" rules="required|max:100">
                    <b-form-input
                      id="fullname"
                      v-model="user.fullname"
                      autofocus
                      placeholder="Nombre completo"
                      name="fullname"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Email *" label-for="email">
                  <validation-provider #default="{ errors }" name="email" rules="email">
                    <b-form-input id="email" v-model="user.email" placeholder="Email" name="email" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Tel *" label-for="tel">
                  <validation-provider #default="{ errors }" name="tel" rules="required|min:8">
                    <b-form-input id="tel" v-model="user.tel" placeholder="Teléfono principal" name="tel" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Empresa *" label-for="empresa">
                  <validation-provider #default="{ errors }" name="empresa" rules="required|min:2">
                    <b-form-input id="empresa" v-model="user.empresa" placeholder="Empresa" name="empresa" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Configuración">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Story ID" label-for="storyid">
                  <v-select v-model="$session.story" label="name" :options="$session.user.myStories" />
                </b-form-group>

                <b-row>
                  <b-col md="3">
                    <b-button :disabled="$apiCall.state" class="mt-1" block variant="primary" type="submit" @click.prevent="openNewStory">
                      + Story
                    </b-button>
                  </b-col>
                  <b-col md="3">
                    <b-button :disabled="$apiCall.state" variant="outline-primary" type="button" class="mt-1" block @click.prevent="editStory">
                      Editar
                    </b-button>
                  </b-col>
                  <b-col md="3">
                    <b-button :disabled="$apiCall.state" variant="outline-primary" type="button" class="mt-1" block @click.prevent="downloadStory">
                      <feather-icon icon="LoaderIcon" size="18" />
                      Cargar
                    </b-button>
                  </b-col>
                </b-row>

                <b-card v-if="showStory">
                  <b-form-group label="ID" label-for="storyId" class="mt-1">
                    <validation-provider #default="{ errors }" name="storyId" rules="max:24|min:24">
                      <b-form-input id="storyId" v-model="story._id" placeholder="Identificador" name="storyId" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Nombre" label-for="storyName" class="mt-1">
                    <validation-provider #default="{ errors }" name="storyName" rules="required">
                      <b-form-input id="storyName" v-model="story.name" placeholder="Nombre de la historia" name="storyName" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Color" label-for="storyColor" class="mt-1">
                    <div
                      v-for="(color, index) in navbarColors"
                      id="storyColor"
                      :key="color"
                      class="p-1 d-inline-block rounded mr-1 cursor-pointer"
                      :class="[`bg-${color}`, { 'border border-light': !index }, { 'mark-active': story.color === color }]"
                      @click="story.color = color"
                    />
                  </b-form-group>

                  <b-form-group label="Branch" label-for="branch" class="mt-1">
                    <validation-provider #default="{ errors }" name="branch" rules="required">
                      <b-form-input
                        id="branch"
                        v-model="story.branch"
                        placeholder="Branch Name, consulte con su administrador (a) de cuenta"
                        name="branch"
                        rules="required"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Trigger ID" label-for="triggerid">
                    <validation-provider #default="{ errors }" name="triggerid" rules="required">
                      <b-form-input
                        id="triggerid"
                        v-model="story.triggerId"
                        placeholder="Trigger ID, consulte con su administrador (a) de cuenta"
                        name="triggerid"
                        rules="required"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-row>
                    <b-col md="10">
                      <b-button :disabled="$apiCall.state || invalid" class="mt-1" block variant="primary" type="button" @click.prevent="addStory">
                        <feather-icon icon="CheckIcon" size="18" />
                      </b-button>
                    </b-col>
                    <b-col md="2">
                      <b-button :disabled="$apiCall.state || invalid" class="mt-1" block variant="danger" type="button" @click.prevent="deleteStory">
                        <feather-icon icon="XCircleIcon" size="18" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

        <hr class="solid" />

        <!-- button -->
        <b-row>
          <b-col md="3">
            <b-button :disabled="invalid || $apiCall.state" class="mt-1" block variant="primary" type="submit" @click.prevent="create">
              Guardar
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button :disabled="invalid" variant="outline-info" type="button" class="mt-1" block @click.prevent="$router.push('/home')">
              Volver
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard, BForm, BRow, BCol, BButton, BFormInput, BFormGroup, BTabs, BTab } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email, max, min, password } from '@validations'
localize('es')

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BTabs,
    BTab,
    vSelect,

    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      passwordFieldTypeNew: 'password',
      strongPwd: true,

      // validations
      required,
      email,
      max,
      min,
      password,
      image: [],

      showStory: false,
      story: {
        _id: '',
        name: '',
        color: '',
        branch: '',
        triggerId: ''
      },
      navbarColors: ['', 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark']
    }
  },
  computed: {
    user() {
      return this.$session.user
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    pwdRules() {
      return `required|min:5|${this.strongPwd ? 'password' : ''}`
    }
  },
  methods: {
    reset() {
      this.showStory = false
      this.story._id = ''
      this.story.name = ''
      this.story.color = ''
      this.story.branch = ''
      this.story.triggerId = ''
    },
    downloadStory() {
      this.$store
        .dispatch('storyStore/readById', this.$session.story._id)
        .then(() => {
          localStorage.setItem('idSelected', this.$session.story._id)
          this.$noti.success('Story: ' + this.$session.story.name)
          this.$emit('onStoryLoaded')
        })
        .catch(() => {})
    },
    addStory() {
      this.$session.story = { ...this.story }
      const newItem = this.$session.user.myStories.find(e => e._id === this.$session.story._id)
      if (!newItem) this.$session.user.myStories = [this.$session.story, ...this.$session.user.myStories]
      else Object.keys(newItem).forEach(i => (newItem[i] = this.$session.story[i]))

      // guarda el perfil del cliente con la modificacion en la lista de Stories
      this.saveStoryAndUser().then(() => {
        this.reset()
      })
    },

    deleteStory() {
      const self = this
      this.$swal({
        title: 'Eliminar?',
        text: `Eliminaremos: ${this.story.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          // elimina story de la lista y de localstorage
          this.$session.user.myStories = this.$session.user.myStories.filter(e => e._id !== this.story._id)
          const idSelected = localStorage.getItem('idSelected')
          if (idSelected === this.story._id) localStorage.removeItem('idSelected')

          this.create().then(() => this.reset())
        }
      })
    },
    openNewStory() {
      this.reset()
      this.showStory = true
    },
    editStory() {
      this.showStory = true

      this.story._id = this.$session.story._id
      this.story.name = this.$session.story.name
      this.story.color = this.$session.story.color
      this.story.branch = this.$session.story.branch
      this.story.triggerId = this.$session.story.triggerId
    },
    saveStoryAndUser() {
      return this.$store
        .dispatch(`storyStore/saveStory`, { _id: this.$session.story._id, name: this.$session.story.name })
        .then(response => {
          this.story._id = response._id
          this.$session.story._id = response._id
          this.create()
        })
        .catch(err => this.$noti.error(`Ha ocurrido un error al crear la nueva historia: ${err}`))
    },
    create() {
      return this.$refs.simpleRules.validate().then(success => {
        if (success) {
          return this.$store
            .dispatch(`userStore/${this.user._id ? 'update' : 'create'}`, this.user)
            .then(() => this.$noti.success('Los datos fueron guardados!'))
            .catch(err => this.$noti.error(`Ha ocurrido un error al guardar el perfil de usuario: ${err}`))
        } else return this.$noti.warn('Hay errores en el formulario')
      })
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#img {
  height: 430px;
  width: 330px;
}
.lbl {
  cursor: pointer;
  background-color: #7367f0;
  color: white;
  font-size: 1rem;
  padding: 0.786rem 1.5rem;
  line-height: 1;
  border-radius: 7px;
}

.img-container {
  position: relative;
  width: 100%;
}

.img-container img {
  width: 100%;
  height: auto;
}

.img-container .divIcon {
  position: absolute;
  bottom: 0;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* background-color: #555; */
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 12px;
  padding: 12px 12px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.img-container .divIcon:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
</style>
