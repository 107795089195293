var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-tabs',{attrs:{"align":"center"}},[_c('b-tab',{attrs:{"title":"Info"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre Completo *","label-for":"fullname"}},[_c('validation-provider',{attrs:{"name":"fullname","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullname","autofocus":"","placeholder":"Nombre completo","name":"fullname","state":errors.length > 0 ? false : null},model:{value:(_vm.user.fullname),callback:function ($$v) {_vm.$set(_vm.user, "fullname", $$v)},expression:"user.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Email","name":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Tel *","label-for":"tel"}},[_c('validation-provider',{attrs:{"name":"tel","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tel","placeholder":"Teléfono principal","name":"tel","state":errors.length > 0 ? false : null},model:{value:(_vm.user.tel),callback:function ($$v) {_vm.$set(_vm.user, "tel", $$v)},expression:"user.tel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Empresa *","label-for":"empresa"}},[_c('validation-provider',{attrs:{"name":"empresa","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"empresa","placeholder":"Empresa","name":"empresa","state":errors.length > 0 ? false : null},model:{value:(_vm.user.empresa),callback:function ($$v) {_vm.$set(_vm.user, "empresa", $$v)},expression:"user.empresa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Configuración"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Story ID","label-for":"storyid"}},[_c('v-select',{attrs:{"label":"name","options":_vm.$session.user.myStories},model:{value:(_vm.$session.story),callback:function ($$v) {_vm.$set(_vm.$session, "story", $$v)},expression:"$session.story"}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"mt-1",attrs:{"disabled":_vm.$apiCall.state,"block":"","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.openNewStory.apply(null, arguments)}}},[_vm._v(" + Story ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"mt-1",attrs:{"disabled":_vm.$apiCall.state,"variant":"outline-primary","type":"button","block":""},on:{"click":function($event){$event.preventDefault();return _vm.editStory.apply(null, arguments)}}},[_vm._v(" Editar ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"mt-1",attrs:{"disabled":_vm.$apiCall.state,"variant":"outline-primary","type":"button","block":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadStory.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"LoaderIcon","size":"18"}}),_vm._v(" Cargar ")],1)],1)],1),(_vm.showStory)?_c('b-card',[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"ID","label-for":"storyId"}},[_c('validation-provider',{attrs:{"name":"storyId","rules":"max:24|min:24"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"storyId","placeholder":"Identificador","name":"storyId"},model:{value:(_vm.story._id),callback:function ($$v) {_vm.$set(_vm.story, "_id", $$v)},expression:"story._id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Nombre","label-for":"storyName"}},[_c('validation-provider',{attrs:{"name":"storyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"storyName","placeholder":"Nombre de la historia","name":"storyName"},model:{value:(_vm.story.name),callback:function ($$v) {_vm.$set(_vm.story, "name", $$v)},expression:"story.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Color","label-for":"storyColor"}},_vm._l((_vm.navbarColors),function(color,index){return _c('div',{key:color,staticClass:"p-1 d-inline-block rounded mr-1 cursor-pointer",class:[("bg-" + color), { 'border border-light': !index }, { 'mark-active': _vm.story.color === color }],attrs:{"id":"storyColor"},on:{"click":function($event){_vm.story.color = color}}})}),0),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Branch","label-for":"branch"}},[_c('validation-provider',{attrs:{"name":"branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"branch","placeholder":"Branch Name, consulte con su administrador (a) de cuenta","name":"branch","rules":"required"},model:{value:(_vm.story.branch),callback:function ($$v) {_vm.$set(_vm.story, "branch", $$v)},expression:"story.branch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Trigger ID","label-for":"triggerid"}},[_c('validation-provider',{attrs:{"name":"triggerid","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"triggerid","placeholder":"Trigger ID, consulte con su administrador (a) de cuenta","name":"triggerid","rules":"required"},model:{value:(_vm.story.triggerId),callback:function ($$v) {_vm.$set(_vm.story, "triggerId", $$v)},expression:"story.triggerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"md":"10"}},[_c('b-button',{staticClass:"mt-1",attrs:{"disabled":_vm.$apiCall.state || invalid,"block":"","variant":"primary","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addStory.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"18"}})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticClass:"mt-1",attrs:{"disabled":_vm.$apiCall.state || invalid,"block":"","variant":"danger","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.deleteStory.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"18"}})],1)],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('hr',{staticClass:"solid"}),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"mt-1",attrs:{"disabled":invalid || _vm.$apiCall.state,"block":"","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_vm._v(" Guardar ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"mt-1",attrs:{"disabled":invalid,"variant":"outline-info","type":"button","block":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/home')}}},[_vm._v(" Volver ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }